import { Box, Center, Image } from "@chakra-ui/react";

import MsLogo from "../assets/ms-logo-colour.jpg";
import MspLogo from "../assets/msp-logo-colour.png";

function FormHeader() {
  return (
    <Box pt={8} pb={4} borderBottom="1px solid #D9D9D9">
      <Center gap={{ base: 1, md: 4 }}>
        <Image
          src={MsLogo}
          alt="ms logo"
          width={{ base: "130px", md: "150px" }}
        />
        <Image
          src={MspLogo}
          alt="msp logo"
          width={{ base: "110px", md: "130px" }}
        />
      </Center>
    </Box>
  );
}

export default FormHeader;
