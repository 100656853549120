// Copied from /shared folder
export type CenterInfo = {
  internalName: string;
  externalName: string;
  address: string | undefined;
  landline: string | undefined;
  mobile: string;
  email: string;
};

export const centres = [
  {
    internalName: "Mind Stretcher Education",
    externalName: "Online Classes",
    address: undefined,
    landline: "6517 9906",
    mobile: "8771 2750",
    email: "onlineclass@mindstretcher.com",
  },
  {
    internalName: "Bishan",
    externalName: "Bishan Central",
    address: "510 Bishan St 13 #03-01 S(570510)",
    landline: "6250 0050",
    mobile: "8790 5286",
    email: "bishan@mindstretcher.com",
  },
  {
    internalName: "Bishan J8",
    externalName: "Bishan @ Junction 8",
    address: "9 Bishan Place #04-02A S(579837)",
    landline: "6334 3066",
    mobile: "8882 3170",
    email: "j8@mindstretcher.com",
  },
  {
    internalName: "Bukit Batok (Main)",
    externalName: "Bukit Batok",
    address: "634 Bukit Batok Central #01-102 S(650634)",
    landline: "6612 8541",
    mobile: "9785 0595",
    email: "bukitbatok@mindstretcher.com",
  },
  {
    internalName: "Lequest",
    externalName: "Bukit Batok West @ Le Quest Mall",
    address: "4 Bukit Batok Street 41 #01-04 S(657991)",
    landline: "6513 0010",
    mobile: "8881 1049",
    email: "lequest@mindstretcher.com",
  },
  {
    internalName: "Bukit Panjang",
    externalName: "Bukit Panjang Plaza",
    address: "1 Jelebu Rd #04-14 S(677743)",
    landline: "6893 0050",
    mobile: "8790 1938",
    email: "bukitpanjang@mindstretcher.com",
  },
  {
    internalName: "West Campus",
    externalName: "Clementi @ Grantral Mall",
    address: "3151 Commonwealth Ave West #03-01 S(129581)",
    landline: "6684 0060",
    mobile: "8790 2205",
    email: "west.campus@mindstretcher.com",
  },
  {
    internalName: "Hougang",
    externalName: "Hougang 1",
    address: "1 Hougang St 91 #01-42 S(538692)",
    landline: "6881 0020",
    mobile: "8790 9590",
    email: "hougang@mindstretcher.com",
  },
  {
    internalName: "Jurong West",
    externalName: "Jurong Point (JP2)",
    address: "63 Jurong West Central 3 #B1-49/50 S(648331)",
    landline: "6255 2900",
    mobile: "8886 9470",
    email: "jurongpoint@mindstretcher.com",
  },
  {
    internalName: "Katong",
    externalName: "Marine Parade @ Parkway Centre",
    address: "1 Marine Parade Central #12-08/09 S(449408)",
    landline: "6636 0010",
    mobile: "9755 6198",
    email: "marineparade@mindstretcher.com",
  },
  {
    internalName: "Novena",
    externalName: "Novena",
    address:
      "United Square Shopping Mall, 101 Thomson Road, #04-20/22, Singapore 307591",
    landline: "6513 6606",
    mobile: "8770 4980",
    email: "novena@mindstretcher.com",
  },
  {
    internalName: "Punggol",
    externalName: "Punggol @ Punggol Plaza",
    address: "168 Punggol Field #04-06 S(820168)",
    landline: "6886 0600",
    mobile: "8790 2685",
    email: "punggol@mindstretcher.com",
  },
  {
    internalName: "Punggol Central",
    externalName: "Punggol Central @ Waterway Point",
    address: "83 Punggol Central #02-02 S(828761)",
    landline: "6386 0030",
    mobile: "8790 9866",
    email: "punggol.central@mindstretcher.com",
  },
  {
    internalName: "Northshore",
    externalName: "Punggol Northshore",
    address: "407 Northshore Drive #03-04 S(820407)",
    landline: "6592 0200",
    mobile: "8772 8965",
    email: "northshore@mindstretcher.com",
  },
  {
    internalName: "One Punggol (Main)",
    externalName: "One Punggol @ One Punggol CC",
    address: "1 Punggol Drive #02-44 S(828629)",
    landline: "6970 0040",
    mobile: "8505 0664",
    email: "onepunggol@mindstretcher.com",
  },
  {
    internalName: "Rivervale",
    externalName: "Rivervale Mall",
    address: "11 Rivervale Crescent #01-33/34 S(545082)",
    landline: "6382 0200",
    mobile: "8882 8780",
    email: "rivervale@mindstretcher.com",
  },
  {
    internalName: "Seletar",
    externalName: "Seletar Mall",
    address: "33 Sengkang West Ave #04-04 S(797653)",
    landline: "6341 6066",
    mobile: "8790 9728",
    email: "seletar@mindstretcher.com",
  },
  {
    internalName: "Sembawang (Main)",
    externalName: "Sembawang",
    address: "355 Sembawang Way #02-04 S(750355)",
    landline: "6752 0200",
    mobile: "8881 3055",
    email: "sembawang@mindstretcher.com",
  },
  {
    internalName: "Compassvale",
    externalName: "Sengkang @ Sengkang Community Hub",
    address: "2 Sengkang Square #04-06 S(545025)",
    landline: undefined,
    mobile: "8790 4150",
    email: "compassvale@mindstretcher.com",
  },
  {
    internalName: "Simei",
    externalName: "Simei @ Eastpoint Mall",
    address: "3 Simei St 6 #06-07 S(528833)",
    landline: "6260 0010",
    mobile: "8789 4890",
    email: "simei@mindstretcher.com",
  },
  {
    internalName: "East Campus",
    externalName: "Tampines Junction",
    address: "300 Tampines Ave 5 #09-03 S(529653)",
    landline: "6781 0030",
    mobile: "8790 9490",
    email: "east.campus@mindstretcher.com",
  },
  {
    internalName: "Central Campus",
    externalName: "Toa Payoh HDB Hub",
    address: "530 Lorong 6 Toa Payoh #02-06 S(310530)",
    landline: "6258 0200",
    mobile: "8790 7502",
    email: "toapayoh@mindstretcher.com",
  },
  {
    internalName: "Woodlands Central",
    externalName: "Woodlands Central @ Woodlands Civic Centre",
    address: "900 South Woodlands Drive #04-02 S(730900)",
    landline: "6737 0030",
    mobile: "9017 4273",
    email: "woodlands@mindstretcher.com",
  },
  {
    internalName: "Woodleigh",
    externalName: "Woodleigh @ The Woodleigh Mall",
    address: "11 Bidadari Park Drive #01-10/11 S(367803)",
    landline: "6904 0010",
    mobile: "8882 3029",
    email: "woodleigh@mindstretcher.com",
  },
  {
    internalName: "Choa Chu Kang",
    externalName: "Yew Tee Point",
    address: "21 Choa Chu Kang North 6 #01-09/10 S(689578)",
    landline: "6465 0100",
    mobile: "8886 2098",
    email: "yewtee@mindstretcher.com",
  },
  {
    internalName: "Yishun",
    externalName: "Yishun @ SAFRA Yishun",
    address: "60 Yishun Ave 4 #01-08 S(769027)",
    landline: "6257 0100",
    mobile: "9232 0633",
    email: "yishun@mindstretcher.com",
  },
];
